import React from 'react';
import Header from '../../components/Header';
import { useTranslation } from 'react-i18next';
import SourceReference from '../../components/SourceReference';
import SectionHeader from '../../components/SectionHeader';
const moment = require('moment');

function PressReleases() {
	const { t } = useTranslation(['translation', 'press']);
	const getTextForPress = (key: string): string => {
		return t(key, { ns: 'press' });
	};
	return (
		<div id="m3Touring-press-releases" className="g81-page">
			<div className="row">
				<Header title={getTextForPress('header')} />
			</div>
			<div className="row">
				<div className="col mb-5">
					<SectionHeader title={getTextForPress('section.official.header')} icon="bi-megaphone" />
					<table className="table table-striped">
						<thead className="table-light">
							<tr>
								<th>{getTextForPress('general.publishedOn')}</th>
								<th>{getTextForPress('general.title')}</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>{moment('2025-01-30').format('L')}</td>
								<td>
									<a
										href="https://www.press.bmwgroup.com/deutschland/article/detail/T0447610DE/der-erste-bmw-m3-cs-touring:-rennstrecken-dna-trifft-maximale-alltagstauglichkeit"
										target="_blank"
										rel="noreferrer"
									>
										Der erste BMW M3 CS Touring: Rennstrecken-DNA trifft maximale Alltagstauglichkeit.
									</a>
								</td>
							</tr>
							<tr>
								<td>{moment('2024-05-29').format('L')}</td>
								<td>
									<a
										href="https://www.press.bmwgroup.com/deutschland/article/detail/T0442275DE/die-neue-bmw-m3-limousine-der-neue-bmw-m3-touring"
										target="_blank"
										rel="noreferrer"
									>
										Die neue BMW M3 Limousine, der neue BMW M3 Touring.
									</a>
								</td>
							</tr>
							<tr>
								<td>{moment('2023-11-08').format('L')}</td>
								<td>
									<a
										href="https://www.press.bmwgroup.com/deutschland/article/detail/T0438187DE/doppelsieg-beim-%E2%80%9Egoldenen-lenkrad%E2%80%9C-2023-hattrick-fuer-bmw-m"
										target="_blank"
										rel="noreferrer"
									>
										Doppelsieg beim „Goldenen Lenkrad“ 2023, Hattrick für BMW M
									</a>
								</td>
							</tr>
							<tr>
								<td>{moment('2022-09-30').format('L')}</td>
								<td>
									<a
										href="https://www.press.bmwgroup.com/deutschland/article/detail/T0404265DE/die-bmw-welt-feiert-50-jahre-bmw-m-drei-wochenenden-ist-die-bmw-welt-mit-den-ikonischen-farben-des-staerksten-buchstabens-der-welt-geschmueckt"
										target="_blank"
										rel="noreferrer"
									>
										Die BMW Welt feiert 50 Jahre BMW M. Drei Wochenenden ist die BMW Welt mit den ikonischen
										Farben des stärksten Buchstabens der Welt geschmückt
									</a>
								</td>
							</tr>
							<tr>
								<td>{moment('2022-09-28').format('L')}</td>
								<td>
									<a
										href="https://www.press.bmwgroup.com/deutschland/article/detail/T0405019DE/die-neue-bmw-m3-limousine-und-bmw-m3-touring-preisliste-fuer-deutschland"
										target="_blank"
										rel="noreferrer"
									>
										Die neue BMW M3 Limousine und BMW M3 Touring - Preisliste für Deutschland
									</a>
								</td>
							</tr>
							<tr>
								<td>{moment('2022-08-05').format('L')}</td>
								<td>
									<a
										href="https://www.press.bmwgroup.com/deutschland/article/detail/T0402825DE/50-jahre-bmw-m-und-20-bmw-m-award:-der-erste-bmw-m3-competition-touring-als-exklusiver-preis-fuer-den-sieger-2022"
										target="_blank"
										rel="noreferrer"
									>
										50 Jahre BMW M und 20. BMW M Award: der erste BMW M3 Competition Touring als exklusiver
										Preis für den Sieger 2022
									</a>
								</td>
							</tr>
							<tr>
								<td>{moment('2022-07-19').format('L')}</td>
								<td>
									<a
										href="https://www.press.bmwgroup.com/deutschland/article/detail/T0402363DE/mehr-individualitaet-mehr-rennsport-feeling:-die-bmw-m-performance-parts-fuer-den-ersten-bmw-m3-touring"
										target="_blank"
										rel="noreferrer"
									>
										Mehr Individualität, mehr Rennsport-Feeling: Die BMW M Performance Parts für den ersten
										BMW M3 Touring
									</a>
								</td>
							</tr>
							<tr>
								<td>{moment('2022-06-23').format('L')}</td>
								<td>
									<a
										href="https://www.press.bmwgroup.com/deutschland/article/detail/T0401755DE/weltpremiere-beim-goodwood-festival-of-speed:-das-erste-bmw-m3-touring-motogp%E2%84%A2-safety-car"
										target="_blank"
										rel="noreferrer"
									>
										Weltpremiere beim Goodwood Festival of Speed: Das erste BMW M3 Touring MotoGP™ Safety Car
									</a>
								</td>
							</tr>
							<tr>
								<td>{moment('2022-06-22').format('L')}</td>
								<td>
									<a
										href="https://www.press.bmwgroup.com/deutschland/article/detail/T0399336DE/der-erste-bmw-m3-touring"
										target="_blank"
										rel="noreferrer"
									>
										Der erste BMW M3 Touring
									</a>
								</td>
							</tr>
							<tr>
								<td>{moment('2022-06-07').format('L')}</td>
								<td>
									<a
										href="https://www.press.bmwgroup.com/deutschland/article/detail/T0396433DE/feierlichkeiten-zum-50-jaehrigen-bestehen-der-bmw-m-gmbh-beim-goodwood-festival-of-speed-2022"
										target="_blank"
										rel="noreferrer"
									>
										Feierlichkeiten zum 50-jährigen Bestehen der BMW M GmbH beim Goodwood Festival of Speed
										2022
									</a>
								</td>
							</tr>
							<tr>
								<td>{moment('2020-08-12').format('L')}</td>
								<td>
									<a
										href="https://www.press.bmwgroup.com/deutschland/article/detail/T0313429DE/dynamik-in-einer-neuen-dimension:-bmw-m-gmbh-startet-testfahrten-mit-dem-ersten-bmw-m3-touring"
										target="_blank"
										rel="noreferrer"
									>
										Dynamik in einer neuen Dimension: BMW M GmbH startet Testfahrten mit dem ersten BMW M3
										Touring
									</a>
								</td>
							</tr>
						</tbody>
					</table>
					<SourceReference
						source="BMW PressClub Deutschland"
						dateAccessed="2024-05-29"
						url="https://www.press.bmwgroup.com/deutschland/"
					/>
				</div>
			</div>
		</div>
	);
}

export default PressReleases;
