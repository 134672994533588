import React from 'react';
import Header from '../components/Header';
import { useTranslation } from 'react-i18next';
const moment = require('moment');

function HomePage() {
	const { t, i18n } = useTranslation();
	const currentLanguage = i18n.language;
	const textDE = (
		<div>
			<p>
				<b>{moment('2025-01-30').format('L')}</b>
				<br />
				Kleinere Updates bzgl. M3 CS Touring
			</p>
			<p>
				<b>{moment('2024-12-28').format('L')}</b>
				<br />
				Update Aussenfarben
			</p>
			<p>
				<b>{moment('2024-08-08').format('L')}</b>
				<br />
				Kleinere Updates bzgl. LCI.
			</p>
			<p>
				<b>{moment('2024-03-05').format('L')}</b>
				<br />
				Kleinere Updates und Verbesserungen.
			</p>
			<p>
				<b>{moment('2024-01-07').format('L')}</b>
				<br />
				Die Seite befindet sich momentan im Aufbau. Dadurch können Funktionen eingeschränkt oder unvollständig
				sein, und die angezeigten Daten könnten ebenfalls begrenzt sein.
			</p>
		</div>
	);
	const textEN = (
		<div>
			<p>
				<b>{moment('2025-01-30').format('L')}</b>
				<br />
				Smaller updates for M3 CS Touring
			</p>
			<p>
				<b>{moment('2024-12-28').format('L')}</b>
				<br />
				Update exterior colors
			</p>
			<p>
				<b>{moment('2024-08-08').format('L')}</b>
				<br />
				Smaller updates for LCI.
			</p>
			<p>
				<b>{moment('2024-03-05').format('L')}</b>
				<br />
				Smaller updates and improvements.
			</p>
			<p>
				<b>{moment('2024-01-07').format('L')}</b>
				<br />
				Currently, the page is under construction, which may result in limited or incomplete functionality and
				displayed data.
			</p>
		</div>
	);

	const textIntroDE = (
		<div>
			<p>
				Gleich vorweg, damit hier keine Missverständnisse entstehen, diese Seite ist nicht finanziell
				motiviert und betrieben.
				<br />
				Die Idee entstand mit Blick auf das bereits bestehende und erfolgreiche{' '}
				<a href="https://www.1er-m-register.de/" target="_blank" rel="noreferrer">
					1er M Register
				</a>
				.
			</p>
			<p>
				Mit dem ersten M3 Touring, der sehr lange von der Community erhofft wurde und final 2022 von BMW und
				der M GmbH veröffentlicht wurde, ist meiner Ansicht nach eine ähnliche Entwicklung zu erwarten. Damit
				ist gemeint, dass das Fahrzeug seine Liebhaber/innen haben und bekommen wird.
				<br />
				In Zukunft soll diese Seite soll den glücklichen Besitzern eine Plattform bieten, sich auszutauschen
				und in Kontakt zu bleiben. Die Seite ist nicht als Konkurrenz zu bestehenden Foren oder Communities
				gedacht, zudem steht es jedem Besitzer eines G81 frei, sich hier zu registrieren oder nicht.
			</p>
			<h6>Warum G81Adventures?</h6>
			<p>
				Der M3 Touring läd aufgrund seiner Karosserieform als Kombi dazu ein ihn vielfältig einzusetzen. Dabei
				ist es ziemlich irrelevant ob er zum Reisen, für die Familie, Ausfahrten oder auf der Rennstrecke
				eingesetzt wird. Alles davon ist möglich. Unter "Adventures", sprich Abenteuern, kann man aber auch
				eine einfache Fahrt zum nächsten Cars & Coffee Treffen, das Tuning oder sonstige Modifikationen
				verstehen. Jeder hat sein eigenes kleines (oder grösseres) Abenteuer mit dem M3 Touring.
			</p>
			<p>
				<br />
				Aktuell befindet sich die Seite im Aufbau, daher sind Registrierungen noch nicht möglich.
			</p>
		</div>
	);
	const textIntroEN = (
		<div>
			<p>
				First and foremost, to avoid any misunderstandings, this site is not financially motivated or
				operated.
				<br />
				The idea arose with a view to the already existing and successful{' '}
				<a href="https://www.1er-m-register.de/" target="_blank" rel="noreferrer">
					1 Series M Register
				</a>
				.
				<br />
			</p>
			<p>
				With the first M3 Touring, which was eagerly anticipated by the community for a long time and finally
				released by BMW and the M GmbH in 2022, I expect a similar development. This means that the vehicle
				will have and gain its enthusiasts.
				<br />
				In the future, this site aims to provide a platform for happy owners to exchange ideas and stay in
				touch. The site is not intended to compete with existing forums or communities, and every owner of a
				G81 is free to register here or not.
				<br />
			</p>
			<h6>Why G81Adventures?</h6>
			<p>
				The M3 Touring's body shape as a station wagon invites its use in a variety of ways. In this regard,
				it's quite irrelevant whether it's used for traveling, family, outings, or on the racetrack. All of
				this is possible. Under "Adventures" one can also understand a simple drive to the next Cars & Coffee,
				tuning the car or any other modification. Everyone has their own little (or bigger) adventure with the
				M3 Touring.
			</p>

			<p>
				<br />
				The site is currently under construction, so registrations are not yet possible.
			</p>
		</div>
	);
	return (
		<div id="home" className="g81-page">
			<div className="row">
				<Header title={t('page.home.header')} />
			</div>
			<div className="row">
				<div className="col">
					{currentLanguage === 'en' && textIntroEN}
					{currentLanguage === 'de' && textIntroDE}
				</div>
			</div>
			<div className="row">
				<div className="col">
					<hr />
					<h5>{t('page.home.news')}</h5>
				</div>
			</div>
			<div className="row">
				<div className="col">
					{currentLanguage === 'en' && textEN}
					{currentLanguage === 'de' && textDE}
				</div>
			</div>
		</div>
	);
}

export default HomePage;
